
import { useTranslation } from "react-i18next";
import image15 from "../assets/image15.jpg"
import image from "../assets/image17.jpg";
import image3 from "../assets/image19.jpg";
import image6 from "../assets/image16.jpg";

import Footer from "./footer";
import {Link } from 'react-router-dom';
const Rooms = () => {
    const {t,i18n} = useTranslation();
    return (
      <div>
      <img class="rooms-image-top" src={image} alt="Card image cap"/>
         
          {/* agiosnikolaos */}
          <div class="container">
            <div class="row mt-3">
            <div class="col">
            <div class="roomsCard">
            <img class="card-img-top"  src={image3} alt="Card image cap"/>
                <div class="card-body">
                    <h5 class="roomsTilte text-center">{t("rooms.thalasa")}</h5>                    
                    <Link class="roomsBtn" as={Link} to="/rooms/thalassa">View Thalasa </Link>
                </div>
            </div>
            </div>
            <div class="col">
            <div class="roomsCard">
            <img class="card-img-top" src={image6} alt="Card image cap"/>
                <div class="card-body">
                    <h5 class="roomsTilte text-center">{t("rooms.agNik")}</h5>                    
                    <Link class="roomsBtn" as={Link} to="/rooms/agiosnikolaos">View Agios Nikolaos </Link>
                </div>
            </div>
            </div>
            </div>
            <div class="row mt-3">
            <div class="col-6">
            <div class="roomsCard">
            <img class="card-img-top"  src={image15} alt="Card image cap"/>
                <div class="card-body">
                    <h5 class="roomsTilte text-center">{t("rooms.xrisiammos")}</h5>                    
                    <Link class="roomsBtn" as={Link} to="/rooms/xrisiammos">View Xrisi Ammos </Link>
                </div>
            </div>
            </div>
            <div class="col-6">
            <div class="roomsCard">
            <img class="card-img-top" src={image15} alt="Card image cap"/>
                <div class="card-body">
                    <h5 class="roomsTilte text-center">{t("rooms.agNik")}</h5>                    
                    <Link class="roomsBtn" type="button" as={Link} to="/rooms/threebeds">View Studio </Link>
                </div>
            </div>
            </div>
            </div>
            </div>
          
            {<Footer/>}
      </div>
    )
}
export default Rooms;