import { createSlice } from "@reduxjs/toolkit";
const initialState ={
    value: 0,
};
const myStore = createSlice({
    name : "cartCount",
    initialState,
    reducers : {
     increment :   (state) =>{
        state.value += 1
     },
     decrement :   (state) =>{
        state.value -= 1
     },
     incrementByAmount :(state,amount) => {
        state.value -= amount.payload
     }
    }

});
export const {increment,decrement,incrementByAmount} = myStore.actions;
export default myStore.reducer;