import './App.css';
import About from './components/about';
import Home from './components/home';
import Rooms from './components/rooms';
import Navbar1 from './components/navbar1';
import Admin from './components/admin';
import NoPage from "./components/nopage";
import { Route,Switch, BrowserRouter , Routes}  from 'react-router-dom';
import React, { Suspense } from 'react';
import ThreeBeds from './components/my-rooms/three-beds';
import AgiosNikalaos from './components/my-rooms/agiosnikolaos';
import XrisiAmmos from './components/my-rooms/xrisiammos';
import Thalassa from './components/my-rooms/thalassa';

function App() {
  return (
    <div >
        <Navbar1 />     
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="rooms" element={<Rooms />} />
          <Route path="/about" element={<About />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/rooms/threebeds" element={<ThreeBeds />} />
          <Route path="/rooms/agiosnikolaos" element={<AgiosNikalaos />} />
          <Route path="/rooms/xrisiammos" element={<XrisiAmmos/>} />
          <Route path="/rooms/thalassa" element={<Thalassa/>} />
          <Route path="*" element={<NoPage />} /> 
        </Routes>    

    </div>
  );
}

export default App;
