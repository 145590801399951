function AgiosNikalaos(){
    return (
  <div>
     <div class="card">
      <img class="card-img-top" src="..." alt="Card image cap"/>
          <div class="card-body">
              <h5 class="card-title text-center">Agios Nikolaos</h5>
             
              <a href="/rooms/agiosnikolaos" class="btn btn-primary">Visit Agios Nikolaos</a>
          </div>
    </div>
  </div>
 )};
 export default AgiosNikalaos;