import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';

import global_en from "./locales/en.json";
import global_gr from "./locales/gr.json";
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import myStoreReducer from "./myStore.js";
import {BrowserRouter, HashRouter} from 'react-router-dom';
const store = configureStore({reducer :{
  cartCount : myStoreReducer
}});

i18next
.use(Backend).use(LanguageDetector) 
.use(initReactI18next).init({
  resources: {
    en: {
      translation: global_en,
    },
    gr: {
      translation: global_gr,
    },
  },
  fallbackLng: 'gr',
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18next= {i18next}>
      <HashRouter >
          <App />
      </HashRouter >
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);


