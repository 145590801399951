
import { Fragment,useState ,useEffect} from "react";
import { useTranslation } from "react-i18next";
import emailjs from 'emailjs-com';
import Footer from "./footer";


function About (){
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [messages, setMes] =useState ("");
    const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
   const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    useEffect(() => {
        const fetchMessages = async () => {
          try {
         
          } catch (error) {
            console.error('Error fetching messages: ', error);
          }
        };
    
        fetchMessages();
      }, []);
    const {t,i18n} = useTranslation();
    const handleSubmit = async (e) => {
        e.preventDefault();
        debugger;
        formData.email = email;
        formData.message =message;
        formData.name =name;
         emailjs.send('service_bkassxc', 'template_fi3653q', formData, 'zejfzfwKopihOqtuM')
            .then((result) => {
                console.log(result.text);
                alert('Email sent successfully!');
            }, (error) => {
                console.log(error.text);
                alert('Failed to send email.');
            });
      };

 return ( 
 <>

 <h1 className="about">About us</h1>
 <div className="mapWrap"> 

 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3100.03070697362!2d23.332632109522834!3d39.014613023141905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a7290245c3b6b7%3A0xc7ac6fab3d6c7539!2sBlue%20Beach!5e0!3m2!1sen!2sgr!4v1704826372073!5m2!1sen!2sgr" width="100%" height= "90%"  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
<p>{t("about.desc")}</p>
 </div>
 <div  className="aboutHome">
    
 <a href="/" className="btn btn-primary align-items-center">Go home</a>
 </div>
 
 <form onSubmit={handleSubmit}>
 <div className="aboutForm">
    <h1 className="aboutH1">Write Us</h1>
 <div className="container">
    <div className="row">
         <div className="col-md">

  <label >
    Name *
    <input type="text" name="name" value={name} placeholder="Name" onChange={(e) => setName(e.target.value)} required="true" />
  </label>
  </div>
  <div className="col-md">
  <label >    Email *   
    <input type="text" name="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} required="true" />
  </label>
  </div>
 </div>
  <div   className="row mt-3">
  <div className="col-md">
  <label>
    Message
    <textarea type="text" name="message" value={message} placeholder="Message" onChange={(e) => setMessage(e.target.value)} />
  </label>
  </div>
  </div>
  <div>
  <input className="btn btn-primary mt-3" type="submit" value="Submit" />
  </div>
</div>
 </div>
</form>
{<Footer />}
 </>
)};
export default About;