import { useTranslation } from "react-i18next";



import React, { useState ,Fragment} from 'react';


const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const Callto = ({ phone, children,hide }) => {
    return <a hidden={hide} href={`tel:${phone}`}>{children}</a>;
  };
  const {t,i18n} = useTranslation();   
    return(
        <>
    







          {/* <button onClick={() => setIsOpen(!isOpen)} >  Click me    </button> */}
          <footer className="footer" hidden ={isOpen}>
          <p>{t("footer.address")}</p>
          <p>Ag. Nikolaos Ellinika P.C. 342 00</p>
          <p>   Evia, Greece</p>
          <p>{t("footer.til")} : <Callto  phone="+306946467489" hide={isOpen}>+30 6946467489</Callto></p>
        <p>
           © Copyright 2022 Blue Beach Aparmnent All Rights Reserved 
          </p> 
        </footer>
        </>
    )
};
export default Footer;

// {/* <-- about us --> */}
// <div class="sec1">
// <div > 

//  <p>ABOUT US</p>
//  <h1 className="text-center">{t("about.title")}</h1>  
//  <p>{t("about.home")}</p>

//  </div>
//  <div > 


//  <img className="sec1Img" src={image1} alt="Card image cap"/>
// </div>
//  </div>

