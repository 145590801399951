import { useTranslation } from "react-i18next";
import {Navbar,Nav,Container,NavDropdown} from 'react-bootstrap';
import React, { useState ,Fragment} from 'react';
import { Link } from 'react-router-dom';

const Countries = [
  { label: "English", value: "en" },
  { label: "Greek", value: "gr" },
];



const Navbar1 = () => {
     const {t,i18n} = useTranslation();
     const [isOpen, setIsOpen] = useState( false);
    const onChange = (e) => {    
      if(e == null)
      {
        i18n.changeLanguage("gr");
        return; 
      }
      i18n.changeLanguage(e.value);
    };
    const Callto = ({ phone, children,hide }) => {
      return <a className="btn btn-primary" hidden={hide} href={`tel:${phone}`}>{children}</a>;
    };
    return (
        <div>
          
<Navbar collapseOnSelect expand="lg" bg="light" variant="light">
      <Container>
        <Navbar.Brand href="/">Blue beach apartment           <Callto  phone="+306946467489" hide={!isOpen}>Call us!</Callto></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">{t("navbar.home")}</Nav.Link>
            <Nav.Link as={Link} to="/rooms">{t("navbar.room")}</Nav.Link>     
            <Nav.Link  as={Link} to="/about">{t("navbar.about")}</Nav.Link>
           <Callto  phone="+306946467489" hide={isOpen}>Call us!</Callto>
            {/* </Nav>
          <Nav>
          <Nav.Link href="/about">{t("navbar.about")}</Nav.Link> */}
            <NavDropdown title={t("navbar.selCount")} id="collasible-nav-dropdown">
              {Countries.map(country => (
                <NavDropdown.Item key={country.value} onClick={() => onChange(country)}>{country.label}</NavDropdown.Item>
              ))}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
</Navbar>

        </div>
    )
}

export default Navbar1;