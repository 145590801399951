import { Fragment } from "react";
function nopage(){
    return (
    <>
    <div className="no-page">

     <div class="card">

    <h1> Error 404</h1>
     </div>
     <h1>404</h1>
    </div>
    </>
);
};
export default nopage;