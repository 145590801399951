import { Fragment,useState ,useEffect} from "react";
function Admin () {
  const [data, setData] = useState(null);
    const options ={
     
        method: 'GET',    
        withCredentials: true,    
        crossorigin: true,    
       
        headers: {
          "Accept":"application/json",
             "Content-Type": "application/json"
        },
      };
  
    const changeUrl = () => {        
      const newUrl=   window.location.origin + "/";
      debugger;
      window.location.replace(newUrl);
    }
 
      useEffect(() => {
        if(data != null)
        {
           handleClick();
        }
      }, []);
    
      const handleClick = () => {
        fetch('/profile/GetRandomProfile', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }  
           return response.text();
        })
        .then(text => {  setData(text);   })
        .catch(error => console.error('Fetch error:', error));};
   
     
 return (
    <>
    <p>{data}</p>
    {data ? <pre>{JSON.stringify(data, null, 2)}</pre> : <p>Loading...</p>}
    <button className="btn btn-primary" onClick={() => {handleClick()}} >Click me </button>
    <button  className="btn btn-primary" onClick={() => {changeUrl()}} >Click me in order to change url </button>
    </>
 )
};
export default Admin;
