
import img1 from "../../assets/image17.jpg"
function ThreeBeds(){
   return ( <div>
    <div class="card">
    <img class="card-img-top" src={img1} alt="Card image cap"/>
         <div class="card-body">
             <h5 class="card-title text-center">3 Beds</h5>
             <p class="card-text">.</p>
             <a href="/rooms" class="btn btn-primary">Go somewhere</a>
         </div>
   </div>
 </div>
)};
export default ThreeBeds;