import { useTranslation } from "react-i18next";
function XrisiAmmos(){
    const {t,i18n} = useTranslation();
    return ( 
    
    <div>
     <div class="card">
      <img class="card-img-top" src="..." alt="Card image cap"/>
          <div class="card-body">
              <h5 class="card-title text-center">Xrisi Ammos</h5>
    
              <a href="/rooms" class="btn btn-primary">Go somewhere</a>
          </div>
    </div>
  </div>
 )};
 export default XrisiAmmos;