import { useTranslation } from "react-i18next";
import img1 from "../../assets/image18.jpg"
function Thalassa(){
    const {t,i18n} = useTranslation();
    return ( <div>
     <div class="card">
      <img class="card-img-top" src="" alt="Card image cap"/>
          <div class="card-body">
              <h5 class="card-title text-center">Thalassa</h5>
            
              <a href="/rooms" class="btn btn-primary">Go somewhere</a>
          </div>
    </div>
  </div>
 )};
 export default Thalassa;