import { useTranslation } from "react-i18next";
import image1 from "../assets/image5.jpg";
import image3 from "../assets/image3.jpg";  
import image13 from "../assets/image13.jpg";  
import image17 from "../assets/image17.jpg";  


import React, { useState ,Fragment} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useDispatch } from "react-redux";
import Footer from "./footer";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const Callto = ({ phone, children,hide }) => {
    return <a hidden={hide} href={`tel:${phone}`}>{children}</a>;
  };
  const {t,i18n} = useTranslation();   
    return(
        <>
        <Carousel>
        <Carousel.Item interval={1500}>
          <img className="d-block w-100" src={image1} alt="First slide"/>       
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img className="d-block w-100" src={image3} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <img className="d-block w-100" src={image13} alt="First slide"/>
        </Carousel.Item>
        </Carousel>

        <div class="container mt-5">
  <div class="row">
    <div class="col-sm-8">
  
        <h1 className="text-center">{t("about.title")}</h1>  
        <p>{t("about.home")}</p>
    </div>
    <div class="col-sm-4">
    <img className="sec1Img" src={image1} alt="Card image cap"/>
    </div>
  </div>
  </div>

             
{/* <-- about us --> */}

<div class="container mt-5">
  <div class="row">
  <div class="col-sm-4">
    <img className="sec1Img" src={image17} alt="Card image cap"/>

    </div>
    <div class="col-sm-8">
  
        <h1 className="text-center">{t("rooms.title")}</h1> 
        <p>{t("rooms.parag")}</p>

        <p></p>
    </div>
  
  </div>
  </div>
      
       {<Footer/>}
        </>
    )
};
export default Home;


